import React from 'react'
import classNames from 'classnames'

export default ({
  subtitle,
  title,
  minorTitle,
  paragraph,
  style,
  mild,
  children,
  className
}) => (
  <div className={`basic-content ${className}`} style={style}>
    {subtitle && subtitle.length && (
      <div
        className={classNames({
          'basic-subtitle': true,
          gray: mild
        })}
      >
        {subtitle}
      </div>
    )}
    {title && title.length && (
      <h2 className={classNames({ 'basic-title': true, minor: minorTitle })}>
        {title}
      </h2>
    )}
    <div className="basic-paragraph">
      {typeof paragraph === 'function' ? paragraph() : paragraph}
    </div>
    {children}
  </div>
)
