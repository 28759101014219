import React from 'react'
import { Section, Container, Columns, Column } from 'sqrl-design-system'
import BasicContent from '../BasicContent'
import classNames from 'classnames'

const Benefit = ({ title, content, offset }) => (
  <div className={classNames({ benefit: true, offset: offset })}>
    <h3 className="title">{title}</h3>
    <p className="content">{content}</p>
  </div>
)

export default () => (
  <Section className="bg-accountant">
    <Container className="benefits-container">
      <Columns>
        <Column>
          <BasicContent
            className="benefits-left-content"
            mild
            subtitle="For your business"
            title="Benefits"
            minorTitle
          />
        </Column>
        <Column>
          <Benefit
            title="Augment your health plan"
            content="Improve employee perceptions of your high deductible health plan (HDHP) and proactively curb healthcare costs."
          />
          <Benefit
            offset
            title="Reduce workplace stress"
            content="By providing your employees tools to understand and manage their stress, they can bring their best selves to work."
          />
          <Benefit
            title="Attract and retain talent"
            content="Increase retention and attract talent by adding this innovative benefit to your company package."
          />
          <Benefit
            offset
            title="Get actionable insights"
            content="Get to know your workforce beyond resources, but as people. We can help you make effective decisions on policies, programming and other benefits."
          />
        </Column>
      </Columns>
    </Container>
  </Section>
)
