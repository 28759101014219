import React from 'react'
import {
  PrimaryButton,
  Section,
  Container,
  CustomInput,
  Columns,
  Column
} from 'sqrl-design-system'
import BasicContent from '../BasicContent'

class DiveInSection extends React.Component {
  render() {
    return (
      <Section style={{ backgroundColor: 'white', paddingTop: 0 }}>
        <Container>
          <div className="yellow-box">
            <div
              style={{
                maxWidth: 600,
                marginLeft: 'auto',
                marginRight: 'auto',
                textAlign: 'center'
              }}
            >
              <BasicContent
                style={{
                  maxWidth: 409,
                  marginBottom: 32,
                  marginLeft: 'auto',
                  marginRight: 'auto'
                }}
                mild
                title="Ready to dive in?"
                paragraph="Sign up to get early access and product updates. Let’s get to know and grow the well-being of your workforce together!"
              />
              <Columns>
                {/*
                  <Column>
                    <CustomInput label="Email Address" />
                  </Column>
                  */}

                <Column>
                  <PrimaryButton
                    style={{
                      minWidth: 200,
                      marginTop: 2
                    }}
                    onClick={this.props.onRequestEarlyAccess}
                  >
                    Request Access
                  </PrimaryButton>
                </Column>
              </Columns>

              <p className="lead">
                Got some questions? Let’s{' '}
                <a
                  href="https://calendly.com/sqrl"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  schedule a call
                </a>
              </p>
            </div>
          </div>
        </Container>
      </Section>
    )
  }
}
export default DiveInSection
