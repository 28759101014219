import React from 'react'
import { Section, Container, Columns, Column } from 'sqrl-design-system'
import BasicContent from '../BasicContent'

const Tile = ({ icon, title, text }) => (
  <div className="feature-tile">
    <img className="icon-left" src={icon} />
    <h4 className="title">{title}</h4>
    <p className="text">{text}</p>
  </div>
)

export default () => (
  <Section className="who-its-for--section">
    <Container style={{ maxWidth: 640 }}>
      <BasicContent
        mild
        subtitle="Who it's for"
        title="Made especially for startups and small businesses."
        minorTitle
        paragraph="Our digital platform goes beyond the office environment to support remote teams, skilled labor environments and on-demand workers."
        style={{ marginLeft: 32, marginBottom: 48 }}
      />

      <Columns>
        <Column>
          <Tile
            title="Office Workers"
            text="Transform your sedentary office environment into an active one and improve performance."
            icon="/icons/monitor.svg"
          />
        </Column>
        <Column>
          <Tile
            title="Remote Teams"
            text="Make well-being the common ground across distributed teams to get teams more engaged."
            icon="/icons/world.svg"
          />
        </Column>
      </Columns>
      <Columns style={{ marginBottom: 80 }}>
        <Column>
          <Tile
            title="Physical Laborers"
            text="Give recognition for hard work and how it helps them build their well-being."
            icon="/icons/delivery-van.svg"
          />
        </Column>
        <Column>
          <Tile
            title="On Demand Workers"
            text="Improve employer perception by providing innovative and competitive benefits."
            icon="/icons/on-demand.svg"
          />
        </Column>
      </Columns>
    </Container>
  </Section>
)
