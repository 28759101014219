import React from 'react'

const trackWidth = 184

export default ({ max, current }) => {
  const safeMax = max || 1

  return (
    <div className="slideshow-indicator-wrapper">
      <div className="slideshow-indicator-track">
        <div
          className="slideshow-indicator-active"
          style={{
            width: `${100 / safeMax}%`,
            transform: `translateX(${current * 100.0}%)`
          }}
        ></div>
      </div>
    </div>
  )
}
