import React from 'react'
import { Section, Container, Columns, Column } from 'sqrl-design-system'
import BasicContent from '../BasicContent'
import SlideShow from '../SlideShow'

const Slide = ({ children }) => (
  <div className="slideshow-slide">{children}</div>
)

const Slide1 = () => (
  <Slide>
    <Columns isVCentered={true}>
      <Column style={{ textAlign: 'center' }}>
        <img src="/images/workforce/steps-lefti-px@3x.png" />
      </Column>
      <Column>
        <BasicContent
          title="Stay physically active"
          minorTitle
          paragraph="Three out of four Americans will be prediabetic by the age of 50. That’s why it’s so important to break out of a sedentary work culture at in a pragmatic way."
        />
      </Column>
    </Columns>
  </Slide>
)

const Slide2 = () => (
  <Slide>
    <Columns isVCentered={true}>
      <Column style={{ textAlign: 'center' }}>
        <img src="/images/workforce/center-savingsi-px@3x.png" />
      </Column>
      <Column>
        <BasicContent
          title="Build financial resilience"
          minorTitle
          paragraph={() => (
            <p>
              Financial stress is a leading contributor to work related stress.
              42% of Americans are saving nothing for healthcare expenses not
              covered by insurance.
              <br />
              <br />
              Encourage and reward a culture of saving that helps your people
              build the financial confidence to handle emergency spend without
              borrowing or using credit.
            </p>
          )}
        />
      </Column>
    </Columns>
  </Slide>
)

const Slide3 = () => (
  <Slide>
    <Columns isVCentered={true}>
      <Column style={{ textAlign: 'center' }}>
        <img src="/images/workforce/mood-righti-px@3x.png" />
      </Column>
      <Column>
        <BasicContent
          title="Practice gratitude daily"
          minorTitle
          paragraph={() => (
            <p>
              Start and finish each day by journaling your gratitude to make
              happiness your way of being.
              <br />
              <br />
              This simple yet powerful habit allows a person to adopt a mindset
              of abundance that goes beyond just managing stress.
            </p>
          )}
        />
      </Column>
    </Columns>
  </Slide>
)

class WFSlideShow extends React.Component {
  state = {
    activeIndex: 0
  }
  onSlideChanged = index => {
    this.setState({
      activeIndex: index
    })
  }
  render() {
    const currentIndex = this.state.activeIndex
    return (
      <Section style={{ backgroundColor: 'white' }}>
        <Container style={{ maxWidth: 960 }}>
          <div className={`slideshow-colors slide-${currentIndex + 1}`}>
            <SlideShow
              onSlideChanged={this.onSlideChanged}
              slides={[<Slide1 />, <Slide2 />, <Slide3 />]}
              staticContent={
                <BasicContent
                  mild
                  subtitle="For Employees"
                  title="Drive behavioral change"
                  paragraph={() => (
                    <p>
                      Enable your people to take control of their own
                      development with an app that’s easy for everyone to use.
                      Now available on both{' '}
                      <a
                        href="https://itunes.apple.com/us/app/sqrl/id1350048019?ls=1&mt=8"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        iOS
                      </a>{' '}
                      and{' '}
                      <a
                        href="https://play.google.com/store/apps/details?id=com.eighty4inc.sqrl"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Android
                      </a>
                      .
                    </p>
                  )}
                  className="drive-behavioral-change"
                />
              }
            />
          </div>
        </Container>
      </Section>
    )
  }
}

export default WFSlideShow
