import React from 'react'

import {
  PrimaryButton,
  Section,
  Container,
  CustomInput,
  Tag,
  Columns,
  Column
} from 'sqrl-design-system'

class HeroSection extends React.Component {
  render() {
    return (
      <Section className="pink-gradient">
        <Container className="workforce">
          <div
            style={{
              maxWidth: 600,
              marginLeft: 'auto',
              marginRight: 'auto',
              marginTop: 100
            }}
          >
            <p className="topic">
              For Employers{' '}
              <Tag isColor="primary" className="beta-tag">
                Beta
              </Tag>
            </p>
            <h1 className="super">
              Get to know and grow the well-being of your workforce.
            </h1>

            <Columns style={{ marginTop: 40 }}>
              {/*
                  <Column isSize={7}>
                    <CustomInput label="Email Address" />
                  </Column>
                  */}

              <Column style={{ textAlign: 'center' }}>
                <PrimaryButton
                  style={{
                    minWidth: 200,
                    marginTop: 2
                  }}
                  onClick={this.props.onRequestEarlyAccess}
                >
                  Request Access
                </PrimaryButton>
                <div
                  className="small-text has-text-gray"
                  style={{ marginTop: 16 }}
                >
                  Currently in private beta.
                </div>
              </Column>
            </Columns>
          </div>
          <div style={{ width: '100%', textAlign: 'center' }}>
            <img
              src="/images/workforce/workforce-hero-shot@3x.png"
              style={{
                width: '100%',
                maxWidth: 812,
                marginTop: 48,
                marginLeft: 'auto',
                marginRight: 'auto',
                pointerEvents: 'none'
              }}
            />
          </div>
        </Container>
      </Section>
    )
  }
}
export default HeroSection
