import React from 'react'
import { Section, Container } from 'sqrl-design-system'

export default () => (
  <Section style={{ backgroundColor: 'white' }}>
    <Container className="what-we-are-not">
      <div className="border-box">
        <h1 className="border-box-title">What we're not.</h1>
        <p>
          SQRL is not another wellness program, or another HR tool — the world
          doesn’t need another one of those. It’s the simplest, fastest and most
          intuitive way to build a healthy work environment. We focus on
          developing the three most challenging dimensions of wellbeing -
          financial, emotional and physical.
        </p>
      </div>
    </Container>
  </Section>
)
