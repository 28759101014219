import React from 'react'
import { ReactTypeformEmbed } from 'react-typeform-embed'

export default React.forwardRef((props, ref) => (
  <ReactTypeformEmbed
    popup={true}
    url="https://om43.typeform.com/to/qcyasG"
    ref={ref}
  />
))
