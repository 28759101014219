import React from 'react'
import {
  Button,
  PrimaryButton,
  Section,
  Container,
  Columns,
  Column
} from 'sqrl-design-system'
import BasicContent from '../BasicContent'

class EvaluateSection extends React.Component {
  render() {
    return (
      <Section>
        <Container>
          <div className="purple-box">
            <Columns isVCentered={true}>
              <Column>
                <BasicContent
                  mild
                  subtitle="For The Business"
                  title="Evaluate what you offer & how it's working."
                  paragraph={() => (
                    <p>
                      Track user activation, activity and long term behavior
                      change to understand the impact on your workforce.
                      <br />
                      <br />
                      Get actionable insights that help you determine how to
                      invest in your workforce more strategically.
                    </p>
                  )}
                  style={{
                    maxWidth: 336
                  }}
                />
              </Column>
              <Column>
                <div className="box-image-right wf-chart-image"></div>
              </Column>
            </Columns>
          </div>

          <div className="box teal-box">
            <Columns isVCentered={true}>
              <Column isSize={8}>
                <h2>Try 1 month free</h2>
                <p className="launch-in-minutes">
                  Launch your own well-being program within minutes.
                </p>
                <PrimaryButton
                  style={{
                    width: 200
                  }}
                  onClick={this.props.onRequestEarlyAccess}
                >
                  Get early access
                </PrimaryButton>
              </Column>
              <Column className="dotted-left">
                <h3 style={{ color: 'white', marginBottom: 16 }}>
                  Want to talk about it?
                </h3>
                <Button
                  href="https://calendly.com/sqrl"
                  target="_blank"
                  rel="noreferrer noopener"
                  style={{ fontWeight: 500 }}
                >
                  Schedule a call
                </Button>
              </Column>
            </Columns>
          </div>
        </Container>
      </Section>
    )
  }
}

export default EvaluateSection
