import React from 'react'
import { Section, Container, Columns, Column } from 'sqrl-design-system'
import BasicContent from '../BasicContent'

export default () => (
  <Section
    style={{ backgroundColor: 'white', paddingTop: 200, paddingBottom: 200 }}
  >
    <Container>
      <Columns isVCentered={true} isCentered={true}>
        <Column>
          <img
            style={{ maxWidth: '100%' }}
            src="/images/workforce/inclusive-by-design@3x.png"
          />
        </Column>
        <Column>
          <div style={{ padding: 40 }}>
            <BasicContent
              mild
              subtitle="For work culture"
              title="Inclusive by design."
              minorTitle
              paragraph={() => (
                <p>
                  For the first time, there are five generations represented in
                  our workforce — each with their own goals, values and
                  challenges.
                  <br />
                  <br />
                  That’s why we took the help of certified life coaches to
                  design a judgement free experience that meets you where you’re
                  at.
                </p>
              )}
            />
          </div>
        </Column>
      </Columns>
    </Container>
  </Section>
)
