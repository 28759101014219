import React from 'react'
import { Section, Container, Columns, Column } from 'sqrl-design-system'
import BasicContent from '../BasicContent'

export default () => (
  <Section
    className="getting-started-section"
    style={{ backgroundColor: 'white' }}
  >
    <Container className="narrow">
      <Columns isVCentered>
        <Column className="centered-left-on-mobile">
          <BasicContent
            className="getting-started-content"
            mild
            subtitle="Getting Started"
            title="Simple, fast and intuitive."
            minorTitle
            paragraph="We cut out all the middlemen and the tedious, time consuming aspects of traditional plans to put you in the control"
          />
        </Column>
        <Column className="centered-left-on-mobile">
          <ol className="vertical-steps">
            <li>
              <strong>Create Account</strong>
              <p>
                Tell us a little about yourself and your company to create an
                account.
              </p>
            </li>
            <li>
              <strong>Verify Identity</strong>
              <p>
                To protect you and your company’s identity, we’ll ask you for
                some information for ID verification.
              </p>
            </li>
            <li>
              <strong>Setup Program</strong>
              <p>
                Add employees, personalize incentives and link a payment source.
              </p>
            </li>
          </ol>
        </Column>
      </Columns>
    </Container>
  </Section>
)
