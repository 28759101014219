import React from 'react'
import { Section, Container } from 'sqrl-design-system'
import BasicContent from '../BasicContent'

export default () => (
  <Section>
    <Container style={{ maxWidth: 774 }}>
      <BasicContent
        mild
        subtitle="For Employers"
        title="Personalized to your budget."
        paragraph="Choose the milestones and determine the payouts to provide employees
  meaningful cash incentives that meet your company values and budget."
        className="personalized-to-budget"
      />
      <div style={{ textAlign: 'center' }}>
        <img
          src="/images/workforce/payouts@3x.png"
          style={{
            maxWidth: 774,
            width: '100%',
            marginLeft: 'auto',
            marginRight: 'auto'
          }}
        />
      </div>
      <div style={{ textAlign: 'center', marginTop: 64 }}>
        <p className="lead">
          Want to know more about how incentive payouts work? <a>Learn more</a>
        </p>
      </div>
    </Container>
  </Section>
)
