import React from 'react'
import Indicator from './Indicator'

const autoplaySlideDuration = 6000

class SlideShow extends React.Component {
  state = {
    currentIndex: 0
  }

  startAutoplay = () => {
    const autoplayInterval = setInterval(this.next, autoplaySlideDuration)
    this.setState({
      autoplayInterval
    })
  }

  stopAutoplay = () => {
    if (this.state.autoplayInterval) {
      clearInterval(this.state.autoplayInterval)
      this.setState({
        autoplayInterval: null
      })
    }
  }

  setSlide = index => {
    this.setState({
      currentIndex: index
    })
    if (this.props.onSlideChanged) {
      this.props.onSlideChanged(index)
    }
  }

  componentDidMount() {
    this.startAutoplay()
  }

  componentWillUnmount() {
    this.stopAutoplay()
  }

  next = () => {
    const { slides } = this.props
    if (slides && slides.length) {
      let index = this.state.currentIndex
      index++
      if (index > slides.length - 1) {
        index = 0
      }
      this.setSlide(index)
    }
  }

  nextWithClick = () => {
    this.stopAutoplay()
    this.next()
    this.startAutoplay()
  }

  render() {
    const { staticContent, slides } = this.props
    const { currentIndex } = this.state

    let slide = null
    if (slides && slides.length && slides.length > currentIndex) {
      slide = slides[currentIndex]
    }

    return (
      <div className="slideshow" onClick={() => this.nextWithClick()}>
        <Indicator max={slides.length} current={currentIndex} />
        {staticContent}
        <hr />
        {slide}
      </div>
    )
  }
}

export default SlideShow
