import React from 'react'
import Layout from '../layout'
import Header from '../components/Header'
import Footer from '../components/Footer'
import SEO from '../components/SEO'
import {
  Benefits,
  Budget,
  DiveIn,
  Evaluate,
  GettingStarted,
  Hero,
  InclusiveByDesign,
  MadeForStartups,
  SlideShow,
  WhatWeAreNot,
  PrivateBetaTypeform
} from '../components/Workforce'

class WorkforcePage extends React.Component {
  render() {
    return (
      <Layout>
        <SEO title="Workforce" />
        <Header workforce />

        <PrivateBetaTypeform ref={item => (this.typeform = item)} />

        <div className="workforce">
          <Hero
            onRequestEarlyAccess={() =>
              this.typeform ? this.typeform.typeform.open() : null
            }
          />
          <WhatWeAreNot />
          <SlideShow />

          <div style={{ backgroundColor: 'white' }}>
            <Budget />
            <Evaluate
              onRequestEarlyAccess={() =>
                this.typeform ? this.typeform.typeform.open() : null
              }
            />
          </div>

          <InclusiveByDesign />

          <MadeForStartups />

          <Benefits />

          <GettingStarted />

          <DiveIn
            onRequestEarlyAccess={() =>
              this.typeform ? this.typeform.typeform.open() : null
            }
          />
        </div>
        <Footer style={{ backgroundColor: 'white' }} />
      </Layout>
    )
  }
}

export default WorkforcePage
